.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
  filter: invert(88%) sepia(39%) saturate(0%) hue-rotate(138deg) brightness(90%) contrast(104%);
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  z-index: 100000001 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555; 
}

#pointer {
  width: 12%;
  height: 100px;
  position: relative;
  margin-left: 65px;
  background: #5f8ab8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  padding-left: 50px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all ease 0.5s;
  min-width: 175px;
}

#pointer:hover {
  height: 105px;
  margin-left: 52px;
  width: 13%;
  background-color: #007bff;
}

#pointer:hover::before {
  border-left: 52px solid #007bff;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
  right: -52px;
}

#pointer:hover::after {
  border-left: 52px solid white;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
}

#pointer:first-child {
    margin-left: 0px;
}

#pointer:last-child {
  margin-right: 25px;
}

#pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 50px solid white;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  transition: all ease 0.5s;
}
#pointer:before {
  content: "";
  position: absolute;
  right: -50px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 50px solid #5f8ab8;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  transition: all ease 0.5s;
}