::root {
    --answer-font-family: "Nunito", sans-serif;
}

@keyframes supportFastActionsIn {
    0% {
        bottom: 0px;
        opacity: 0;
    }
    100% {
        bottom: 116px;
        opacity: 1;
    }
}

.answerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8.1285px;
    gap: 5.42px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 5.419px;
}

.answerText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #323130;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 11px;
    white-space: normal;
    word-wrap: break-word;
    max-width: 800px;
    overflow-x: auto;
    overflow-y: hidden;
    font-family: var(--answer-font-family);
}


.answerFooter {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    justify-content: space-between;
}

.answerDisclaimerContainer {
    margin: 11px;
    display: flex;
}

.followUpDisclaimerContainer {
    margin: 11px;
}

.answerDisclaimer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 11px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: left;
    color: #707070;
    font-family: var(--answer-font-family);
}

.answerFeedback {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    color: #707070;
    padding-top: 5px;
    padding-left: 10px;
}

.answerFeedbackIcon 
{
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
}


.citationContainer {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #115EA3;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px;
    gap: 4px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}

.followupQuestionContainer {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: block;

    color: #115EA3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.followupQuestionContainer:hover {
    text-decoration: underline;
    cursor: pointer;
}

.citationContainer:hover {
    text-decoration: underline;
    cursor: pointer;
}

.citation {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
    z-index: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #424242;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
}

.citation:hover {
    text-decoration: underline;
    cursor: pointer;
}

.accordionIcon {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 4px;
    color: #616161;
    font-size: 10px;
}

.accordionIcon:hover {
    cursor:pointer;
}

.accordionTitle {
    margin-right: 5px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #616161;
    font-family: var(--answer-font-family);
}


.accordionTitle:hover {
    cursor:pointer;
}



.clickableSup {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #424242;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
}

.clickableSup:hover {
    text-decoration: underline;
    cursor: pointer;
}

sup {
    font-size: 10px;
    line-height: 10px;
}

/*MEDIA QUERIE*/


.answerSubtext {
    font-size: 14px;
}

/*Phones*/
@container (max-width: 600px) {
    .answerText {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .answerSubtext {
        font-size: 12px;
    }

    .answerFeedbackIcon {
        font-size: 13px;
    }

    .answerFeedback {
        padding-left: 0px;
    }

    .answerDisclaimer {
        font-size: 9px;
    }

    .citationContainer {
        font-size: 12px;
    }
}


/*Tablets*/
@container (min-width: 600px) {
    .answerText {
        font-size: 14px;
        line-height: 20px;
        margin-top: 11px;
        margin-bottom: 11px;
    }
    .answerDisclaimer {
        font-size: 11px;
    }
    .answerFeedback {
        padding-left: 10px;
    }
    .answerFeedbackIcon {
        font-size: 14px;
    }
    .citationContainer {
        font-size: 10px;
    }
}

/*Desktop*/
@container (min-width: 992px) {
    .answerText {
        font-size: 14px;
        line-height: 20px;
        margin-top: 11px;
        margin-bottom: 11px;
    }
    .answerFeedbackIcon {
        font-size: 16px;
    }
    .answerFeedback {
        padding-left: 10px;
    }
    .answerDisclaimer {
        font-size: 12px;
    }
    .citationContainer {
        font-size: 12px;
    }
}


.supportContainer {
    background-color: #ffffff;
    color: #2A7DE1;
    border: 1px solid #2A7DE1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 4px;
    font-family: 'Nunito';
    border-radius: 16px;
    cursor: pointer;
}

.supportFastActionsContainer {
    position: absolute;
    height: 32px;
    bottom: 116px;
    left: 0px;
    animation: supportFastActionsIn 1s normal;
}

.supportContainer:hover {
    background-color: #2A7DE1;
    color: white;
    transition: all 0.4s;
}

.supportContainer > span
{
    font-size: calc(0.1vw + 10px);
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 5px;
}

.cursor {
    display: inline-block;
    width: 1ch;
    animation: flicker 0.5s infinite;
    margin-bottom: 4px;
  }