:root {
    --chat-font-family: "Nunito", sans-serif;
}

.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    container-type: size;
}

.header {
    background-color: #f2f2f2;
}

.headerContainer {
    display: flex;
    justify-content: left;
    align-items: center;
}

.headerTitleContainer {
    display: flex;
    align-items: center;
    margin-left: 14px;
    text-decoration: none;
}

.headerTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    color: #242424;
    font-family: var(--chat-font-family);
}

.eosHeaderTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    color: #242424;
    font-family: "Open Sans", sans-serif;
}

.headerIcon {
    height: 32px;
    width: 32px;
    margin-left: 36px;
}

.shareButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    gap: 4px;
    width: 86px;
    height: 32px;
    background: radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.shareButton {
    color: #FFFFFF;
}

.shareButtonText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.urlTextBox {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}

.copyButtonContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    gap: 4px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}

.copyButtonContainer:hover {
    cursor: pointer;
    background: #D1D1D1;
}

.copyButton {
    color: #424242;
}

.sosmartCopyButtonText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
    font-family: "Nunito", sans-serif;
}

.eosCopyButtonText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
    font-family: "Open Sans", sans-serif;
}

/*MEDIA QUERY*/

/*Phones*/
@media only screen and (max-width: 600px) {
    .header {
        display: none;
    }
}

/*Tablets*/
@media only screen and (min-width: 600px) {
    .header {
        display: none;
    }
}

/*DESKTOP*/
@media only screen and (max-width: 1200px) {
    .header {
        display: none;
    }
}

/*DESKTOP*/
@media only screen and (min-width: 1201px) {
    .header {
        display: block;
    }
}