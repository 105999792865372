.title-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: stretch;
    padding: 5px;
    padding-left: 15px;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}