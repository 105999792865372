.scroll-main {
    width: 100%;
    position: relative;
    bottom: 20px;
    display: flex; 
    align-items: center;
    height: 50px;
}

.scroll-container {
    background-color: #007bff;
    color: white;
    height: 40px;
    width: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    transition: all ease 0.2s;
}

.scroll-container:hover {
    background-color: #29508f;
    height: 43px;
    width: 43px;
    border-radius: 22px;
}