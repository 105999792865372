.item-container {
    height: 50px;
    width: 100%
}

.item-container:hover {
    background-color: #f5f5f5;
    cursor: pointer; 
    border-right: 5px solid #007bff; 
}

.item-container.active {
    background-color: #f5f5f5;
    cursor: pointer; 
    border-right: 5px solid #007bff; 
}

.item-container.active > .item-name > label {
    color: #007bff;  
}

.item-container.active > .item-icon-container > .item-icon {
    background-color: #007bff;  
}

.item-container:hover .item-icon {
    background-color: #007bff;  
}

.item-container:hover .item-name > label {
    color: #007bff;  
}

.item-name > label {
    color: #5f8ab8;
    font-size: 15px;
    cursor: pointer; 
}

.item-description {
    font-size: 12px;
    padding-bottom: 5px;
    cursor: pointer; 
}

.item-icon-container {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    padding-left: 10px;
    cursor: pointer; 
}

.item-icon {
    background-color: #5f8ab8;
    padding: 10px;
    color: white;
    border-radius: 30px;
    cursor: pointer; 
}

.item-information-container {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer; 
}