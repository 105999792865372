.ck.ck-editor__main {
    height: calc(100vh - 550px) !important;
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
    height: 95%;
}

.ck-editor__editable_inline {
    height: 100%;
}