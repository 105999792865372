
@keyframes maxHeightAnimation {
    0%   {max-height: 100vh; opacity: 0.9; }
    10%   {max-height: 90vh; opacity: 0.7; }
    20%   {max-height: 80vh; opacity: 0.5; }
    30%   {max-height: 70vh; opacity: 0.3; }
    40%   {max-height: 60vh; opacity: 0.2; }
    50%   {max-height: 50vh; opacity: 0; }
    60%   {max-height: 40vh; opacity: 0; }
    70%   {max-height: 30vh; opacity: 0; }
    80%   {max-height: 20vh; opacity: 0; }
    90%   {max-height: 10vh; opacity: 0; }
    100%   {max-height: 0vh; opacity: 0; }
}

@keyframes maxHeightAnimationReverse {
    0%   {max-height: 0vh; opacity: 0; }
    10%   {max-height: 10vh; opacity: 0; }
    20%   {max-height: 20vh; opacity: 0; }
    30%   {max-height: 30vh; opacity: 0; }
    40%   {max-height: 40vh; opacity: 0.2; }
    50%   {max-height: 50vh; opacity: 0.4; }
    60%   {max-height: 60vh; opacity: 0.6; }
    70%   {max-height: 70vh; opacity: 0.8; }
    80%   {max-height: 80vh; opacity: 1; }
    90%   {max-height: 80vh; opacity: 1; }
    100%   {max-height: 100vh; opacity: 1; }
}

@keyframes blinkTest
{
    0% {opacity: 0.5;}
    40% {opacity: 0.7;}
    80% {opacity: 0.8;}
    100% {opacity: 1;}
}

@keyframes spinnerFadeIn {
    0%   { opacity: 0; height: 0px; }
    25%   { opacity: 0; height: 0px; }
    50%   { opacity: 0; height: 0px; }
    75%   { opacity: 0.20; height: 20px; }
    80%   { opacity: 0.30; height: 30px; }
    83%   { opacity: 0.40; height: 40px; }
    85%   { opacity: 0.50; height: 50px; }
    89%   { opacity: 0.60; height: 60px; }
    92%   { opacity: 0.70; height: 70px; }
    95%   { opacity: 0.80; height: 80px; }
    98%   { opacity: 0.90; height: 90px; }
    100%   { opacity: 1; height: 100px; }
}

.wizard-body {
    opacity: 1;
    max-height: 100vh;
    animation: maxHeightAnimationReverse 0.8s normal;
    -webkit-animation: maxHeightAnimationReverse 0.8s normal;
    -moz-animation: maxHeightAnimationReverse 0.8s normal;
}

.wizard-body.hide {
    opacity: 0;
    max-height: 0vh;
    animation: maxHeightAnimation 0.8s normal;
    -webkit-animation: maxHeightAnimation 0.8s normal;
    -moz-animation: maxHeightAnimation 0.8s normal;
}

.wizard-spinner {
    opacity: 0;
    display: none;
}

.wizard-spinner.show {
    opacity: 1;
    height: 100px;
    display: flex;
    animation: spinnerFadeIn 0.3s normal;
}

.spinner-blink {
    animation: blinkTest 1s infinite;
}