
.brand-image {
    height: 50px;
    margin-top: -0.5rem;
    opacity: .8;
    width: 40px;
}

*, .login-box.custom {
    box-sizing: border-box;
}


.content-style-dark {
    background-color: black;
    border-bottom: 1px solid black;
    color: white;
    line-height: 50px;
    font-size: 18px;
    display: inline-block;
    width: 100%;
}

.label-style-dark {
    font-style: bold; 
    color: white; 
    font-weight: 500; 
    font-size: 16px; 
    cursor: pointer
}

.icon-menu-style-dark {
    color: #008089; 
}

.icon-menu-style-dark:hover { 
    color: #015D63 !important; 
}

.comment-icon-style-dark {
    height: 36px;
    margin-top: 5px;
    color: white !important;
    display: flex;
    align-items: center;
    background-color: black;
    border-radius: 0;
    font-family: tondo !important;
}

.comment-icon-style-dark:hover {
    background-color: #015D63;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.2s, border-radius 0.6s;
}

.language-style-dark {
    font-size: 18px;
    color: white !important;
    background-color: black;
    border-radius: 0;
}

.language-style-dark:hover {
    font-size: 18px;
    background-color: black;
    color: #008089 !important;
    cursor: pointer;
}

.content-style-light {
    background-color: white;
    border-bottom: 1px solid #CFD0D1;
    color: black;
    line-height: 50px;
    font-size: 18px;
    display: inline-block;
    width: 100%;
}

.label-style-light {
    font-style: bold; 
    color: #2A7DE1; 
    font-weight: 500; 
    font-size: 16px; 
    cursor: pointer
}

.icon-menu-style-light {
    color: #2A7DE1; 
}

.icon-menu-style-light:hover { 
    color: #184e8f !important; 
}

.comment-icon-style-light {
    height: 36px;
    margin-top: 5px;
    color: #2A7DE1 !important;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 0;
    font-family: tondo !important;
}

.comment-icon-style-light:hover {
    background-color: #2A7DE1;
    color: white !important;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.2s, border-radius 0.6s;
}

.language-style-light {
    font-size: 18px;
    font-family: tondo !important;
    color: #2A7DE1 !important;
    background-color: white;
}

.language-style-light:hover {
    font-size: 18px;
    background-color: white;
    color: #184e8f !important;
    cursor: pointer;
}